import React, { useState, useEffect } from "react"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Loader from "../components/addon/Loader"

import API from "../api"
import { urlQueryToString } from "../service/helper"

const Search = () => {

    const metaTags = []
    const location = useLocation()

    const [keyword, setKeyword] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchList, setSearchList] = useState([])

    const search = searchText => {
        setLoading(true)
        const json = {
            type: "all",
            categoryType: 'all',
            keyword: searchText,
        }
        API.siteSearch(json)
        .then(response => {
            if (response && response.length > 0) {
                let arr = []
                response.sort((a, b) => {
                    if(a?.title < b?.title) return -1
                    if(a?.title > b?.title) return 1
                    return 0
                })
                response.forEach((el, index) => {
                    let obj = {
                        type: el?.type,
                        data: [el]
                    }
                    if(arr?.length) {
                        let oldType = arr.findIndex(arrEl => arrEl?.type === el?.type)
                        if(oldType !== -1) {
                            arr[oldType].data.push(el)
                        } else {
                            arr.push(obj)
                        }
                    } else {
                        arr.push(obj)
                    }
                })
                let arr2 = arr.filter(el => el?.type.toLowerCase() === 'speciality' || el?.type.toLowerCase() === 'doctor')
                let arr3 = arr.filter(el => el?.type.toLowerCase() !== 'speciality' && el?.type.toLowerCase() !== 'doctor')
                arr2.sort((a, b) => {
                    if(b?.type < a?.type) return -1
                    if(b?.type > a?.type) return 1
                    return 0
                })
                arr3.sort((a, b) => {
                    if(a?.type < b?.type) return -1
                    if(a?.type > b?.type) return 1
                    return 0
                })
                setSearchList([...arr2, ...arr3])
            }
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setSearchList([])
            setLoading(false)
        })
    }

    useEffect(() => {
        if(location?.search && location?.search.includes('search')) {
            let word = location?.search.split('=')
            let mainWord = urlQueryToString(word[word?.length - 1])
            setKeyword(mainWord)
            search(mainWord)
        }
    },[location])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        "/assets/css/common.css", 
                        "/assets/css/listing.css", 
                        "/assets/css/sitemap.css",
                        "/assets/css/accessibility.css"
                    ],
                }}
                tags={metaTags}
            />
            <main class="innerpage pt-first-section">
                <section class="breadcrumbs">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul>
                                    <li><a href="javascript:;">Home</a></li>
                                    <li><span>Search Result</span></li>
                                </ul>
                            </div>
                        </div>      
                    </div>      
                </section>
                <section class="siteMapContent">
                    <h3 class="text-center py-4">Search Result For "<span>{keyword}</span>"</h3>
                    {loading ? <Loader loadingMessage={`Loading Data...`} /> : null}
                    {!searchList?.length && !loading 
                        ? <div class="container mb-4">No Data Found.</div> 
                        : null}
                    {searchList?.length && !loading 
                    ? searchList.map((listItem, index) => {
                        return (
                            <div class="siteMapInner" key={index}>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 pb-3">
                                            <h2 class="topHeading">{listItem?.type}</h2>
                                            <ul class="threeColumn">
                                                {listItem?.data?.length 
                                                ? listItem.data.map(el => {
                                                    return ( 
                                                        <li><Link to={el?.path}>{el?.title}</Link></li>
                                                    )
                                                }) 
                                                : 'No Data Found.'} 
                                            </ul>   
                                        </div>    
                                    </div>   
                                </div>
                            </div>
                        )
                    }) 
                    : null}
                </section> 
                {/* <section class="newsletter">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-7">
                                    <div class="text_content">
                                        <h2>Free E-Newsletter</h2>
                                        <p>Get our free e-newsletter to stay up to date on the latest health information from experts at Hinduja Hospital.</p>
                                    </div>
                            </div>
                            <div class="col-md-5">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="xyz@gmail.com" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                    <div class="input-group-append">
                                        <button class="btn" type="button" id="subscribe">Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>   */}
            </main>
        </Layout>
    )
}

export default Search